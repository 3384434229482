@font-face {
	/* font weight 400 */
	font-family: authorLight;
	src: url(../fonts/Author-Light.otf);
	font-display: swap;
}

@font-face {
	/* font weight 400 */
	font-family: authorRegular;
	src: url(../fonts/Author-Regular.otf);
	font-display: swap;
}

@font-face {
	/* font weight 700 */
	font-family: authorBold;
	src: url(../fonts/Author-Bold.otf);
	font-display: swap;
}

@font-face {
	/* font weight 600 */
	font-family: authorSemiBold;
	src: url(../fonts/Author-Semibold.otf);
	font-display: swap;
}

@font-face {
	/* font weight 500 */
	font-family: authorMedium;
	src: url(../fonts/Author-Medium.otf);
	font-display: swap;
}

body {
	font-family: authorRegular !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: authorRegular !important;
}

html {
	scroll-padding-top: 90px;
}


/* HOME atau INDEX */

.nav-link.active .icon-home {
	filter: invert(15%) sepia(67%) saturate(5667%) hue-rotate(350deg) brightness(102%) contrast(92%);
	position: relative;
	bottom: 3px;
}

.home-icon {
	max-width: 20px;
	width: 20px;
}

.navbar-light .navbar-toggler {
	border-color: #ffffff;
	color: #ffffff;
	margin-bottom: 0;
}

.navbar-light .navbar-toggler:focus {
	background-color: unset;
}

.navbar-light .navbar-toggler:hover {
	background-color: unset;
}

.navbar-light .navbar-toggler-icon {
	filter: invert(0%) sepia(0%) saturate(13%) hue-rotate(161deg) brightness(96%) contrast(100%);
}

.navbar-toggler-icon:focus {
	filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(352deg) brightness(107%) contrast(101%);
	;
}

.search-icon {
	position: absolute;
	top: 27%;
	right: 4%;
	width: 18px;
	height: 18px;
}

.form-control.custom--input {
	background: #E8E8E8;
	border: unset;
	border-radius: 5px;
	font-size: 14px;
	color: #000000;
	width: 100%;
	margin-bottom: 0px;
	box-shadow: unset;
}

.form__search {
	width: 100%;
	margin-right: 30px;
}

.bg--custom {
	background: #ffffff;
	height: 80px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.btn.btn-primary--custom {
	color: #FFFFFF;
	background: #009688;
	margin-bottom: 0px;
	font-size: 14px;
	font-family: authorMedium;
}

.btn.btn-primary--custom-2 {
	color: #FFFFFF;
	background: #009688;
	margin-bottom: 0px;
	font-size: 18px;
}

.btn.btn-secondary--custom {
	color: #009688;
	background: #DFDCEA;
	margin-bottom: 0px;
	font-size: 14px;
	font-family: authorMedium;
}

header .nav-item {
	margin-right: 10px;
}

header .navbar-nav {
	display: flex;
	align-items: center;
}

header .nav-item.custom--mr {
	margin-right: 10px;
}

header .nav-item .nav-link.active {
	border-bottom: 4px solid #ED1C24;
}

header .nav-item .nav-link:hover {
	border-bottom: 4px solid #ED1C24 !important;
}

header .nav-item .nav-link.active {
	color: #ED1C24 !important;
}

header .nav-item .nav-link {
	font-size: 15px;
	font-family: authorSemiBold;
	color: #333333 !important;
	text-transform: uppercase;
	text-align: center;
	line-height: 17px;
	white-space: nowrap;
}

header .navbar-nav .show>.nav-link {
	border-bottom: 4px solid #ED1C24;
}

header .navbar-brand {
	margin-right: 40px;
	margin-left: 30px;
}

.wrapper__user-login {
	margin-right: 30px;
}

.user-login__label {
	font-size: 13px;
	font-family: authorMedium;
	color: #333333;
	text-align: right;
}

.user-login__name {
	font-size: 13px;
	color: #666666;
	text-align: right;
	position: relative;
	top: -10%;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.user-login__img {
	width: 40px;
	height: 40px;
	max-width: 40px;
}

.btn.mobile-login {
	display: none;
}

.carousel {
	margin-top: 80px;
}

.carousel-control-next,
.carousel-control-prev {
	opacity: 1;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	width: 40px;
	height: 40px;
	background: #371F1F !important;
	border-radius: 50%;
}

.logo__gpu-desktop {
	display: block;
	max-width: unset;
}

.logo__gpu-mobile {
	display: none;
}

i.icon--custom-position {
	position: relative;
	right: 25px;
}

.gpu__content.new-release h2 {
	color: #E20A15;
	font-size: 36px;
	font-weight: 400;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: unset;
}

.gpu__content.new-release h3 a {
	color: #000000;
	font-size: 15px;
	font-weight: 400;
	text-align: center;
}

.gpu__content.new-release h3 {
	margin-top: unset;
	margin-bottom: 40px;
	text-align: center;
}

.wrapper__carousel img {
	width: 150px !important;
	height: 100%;
}

.gpu__content.new-release .carousel--custom-height {
	height: 230px;
}

.carousel__category:hover {
	text-decoration: underline;
}

.carousel__author:hover {
	text-decoration: underline;
}

.gpu__content.new-release .carousel__category {
	color: #009688;
	font-size: 15px;
	font-family: authorMedium;
}

.gpu__content.new-release .carousel__title {
	color: #000000;
	font-size: 20px;
	font-family: authorMedium;
	margin: 10px 0px;
	line-height: 22px;
	width: 200px;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* .gpu__content.new-release .carousel__title:hover {
	color: #9B1A1A;
  } */

.gpu__content.new-release .item:hover .carousel__title {
	color: #9B1A1A;
}

.gpu__content.new-release .carousel__author {
	color: #1C79E5;
	font-size: 16px;
	font-family: authorMedium;
	line-height: 18px;
}

.carousel.slide.custom button:hover {
	background-color: unset !important;
}

.carousel.slide.custom button:focus {
	background-color: unset !important;
}

.carousel__detail {
	margin: 0px 20px;
}

.gpu__content.new-release .wrapper__carousel .owl-dot.active span {
	width: 30px !important;
	height: 7px !important;
	border-radius: 10px !important;
	background: #E20A15 !important;
}


.gpu__content.new-release .wrapper__carousel .owl-dot {
	margin-top: 30px;
}

.gpu__content.new-release .wrapper__carousel .owl-dot span {
	width: 7px !important;
	height: 7px !important;
	border-radius: 10px !important;
	background: #C4C4C4 !important;
}

.wrapper__carousel .owl-nav {
	display: none;
}

.fa-arrow-right.custom--color-orange {
	position: relative;
	bottom: -2px;
	font-size: 20px;
	color: #FF8159;
	margin-left: 5px;
}

.fa-arrow-right.custom--color-blue {
	position: absolute;
	font-size: 20px;
	color: #3650DA;
	margin-left: 5px;
	right: 6%;
}

.gpu__content.coming-soon {
	background: #9B1A1A;
	padding: 50px 0px;
}

.gpu__content.coming-soon h2 {
	color: #ffffff;
	font-size: 36px;
	font-weight: 400;
	margin-top: unset;
	margin-bottom: unset;
	text-transform: uppercase;
}

.gpu__content.coming-soon h3 {
	color: #ffffff;
	font-size: 15px;
	font-weight: 400;
	margin-top: 20px;
}

.gpu__content.coming-soon .coming-soon__more {
	color: #ffffff;
	font-size: 18px;
	font-family: authorMedium;
}

.gpu__content.coming-soon .coming-soon__more:hover {
	text-decoration: underline;
}

.fa-arrow-right.custom--color-white {
	position: relative;
	bottom: -2px;
	font-size: 20px;
	color: #ffffff;
	margin-left: 5px;
}

.gpu__content.coming-soon .carousel__title {
	color: #ffffff;
	font-size: 20px;
	font-family: authorMedium;
	margin-bottom: 15px;
	line-height: 22px;
	width: 170px;
}

/* .gpu__content.coming-soon .carousel__title:hover {
	text-decoration: underline;
  } */

.gpu__content.coming-soon .item .carousel__title:hover {
	text-decoration: underline;
}

.gpu__content.coming-soon .carousel__timedate {
	color: #ffffff;
	font-size: 16px;
	font-family: authorMedium;
}

.gpu__content.coming-soon .wrapper__carousel .owl-dots {
	display: none;
}


.gpu__content.bestselling {
	margin-bottom: 250px;
}

.gpu__content.bestselling h2 {
	font-size: 36px;
	color: #333333;
	font-weight: 400;
	margin-bottom: 0px;
	text-transform: uppercase;
}

.gpu__content.bestselling h3 {
	margin-top: unset;
}

.gpu__content.bestselling h3 a {
	font-size: 15px;
	color: #000000;
	font-weight: 400;
}

.gpu__content.bestselling .carousel__category {
	color: #009688;
	font-size: 15px;
	font-family: authorMedium;
}

.gpu__content.bestselling .carousel__title {
	color: #000000;
	font-size: 20px;
	font-family: authorMedium;
	margin: 15px 0px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 22px;
}

.gpu__content.bestselling .carousel__title:hover {
	color: #9B1A1A;
}

.gpu__content.bestselling .carousel__author {
	color: #1C79E5;
	font-size: 16px;
	font-family: authorMedium;
	line-height: 18px;
}

.book__wrapper {
	position: absolute;
	width: 170px;
	margin-top: 15px;
	margin-bottom: 30px;
	height: 140px;
}

.gpu__content.bestselling img {
	width: 170px;
}

.bestselling__wrapper {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	position: relative;
}

.gpu__content.quotes {
	margin-top: 50px;
	background: url('../images/parallax-bg.png');
	width: 100%;
	height: 100%;
	padding: 50px 0px;
	background-repeat: no-repeat;
	background-size: cover;
}

.gpu__content.quotes .wrapper__carousel-quote .owl-dot.active span {
	width: 30px !important;
	height: 7px !important;
	border-radius: 10px !important;
	background: #E20A15 !important;
}

.gpu__content.quotes .wrapper__carousel-quote .owl-dot {
	margin-top: 30px;
}

.gpu__content.quotes .wrapper__carousel-quote .owl-dot span {
	width: 7px !important;
	height: 7px !important;
	border-radius: 10px !important;
	background: #C4C4C4 !important;
}

.wrapper__carousel-quote img.book-quotes__img {
	width: 210px !important;
}

.wrapper__carousel-quote .quotes__wrapper img {
	width: auto !important;
}

.quotes__wrapper.secondary-quotes:hover {
	text-decoration: underline;
}

.quotes__wrapper .main-quotes {
	font-size: 22px;
	font-family: authorSemiBold;
	color: #ffffff;
	margin-top: 15px;
	line-height: 29px;
}

.quotes__wrapper .secondary-quotes {
	font-size: 16px;
	font-family: authorSemiBold;
	color: #ffffff;
}

.quotes__wrapper .author-quotes {
	font-size: 15px;
	font-family: authorSemiBold;
	color: #ffffff;
}

.gpu__content.update-book {
	margin: 30px 0px;
}

.gpu__content.update-book img {
	width: 300px;
	height: 300px;
}

.gpu__content.update-book .row {
	max-width: unset;
}

.gpu__content.update-book h2 {
	font-size: 36px;
	font-weight: 400;
	color: #E20A15;
	margin-top: unset;
	margin-bottom: 20px;
	text-transform: uppercase;
}

.gpu__content.update-book a {
	font-size: 15px;
	font-weight: 400;
	color: #333333;
}

.gpu__content.update-book a:hover {
	color: #9B1A1A;
}

.update-book.title-book {
	width: 100%;
	font-size: 20px;
	font-family: authorSemiBold;
	color: #333333;
	margin-top: 15px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 22px;
	height: 44px;
}

.update-book.title-book:hover {
	color: #9B1A1A;
}

.update-book.category {
	width: 270px;
	font-size: 16px;
	font-weight: 400;
	color: #009688;
	margin: 15px 0px;
}

.update-book.category span {
	font-size: 16px;
	font-weight: 400;
	color: #635858;
}

.update-book.preview {
	width: 270px;
	font-size: 16px;
	font-weight: 400;
	color: #333333;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 22px;
}

.gpu__content.author-ofthemonth {
	margin-top: 60px;
	background: url('../images/content-bg.png');
	width: 100%;
	height: 100%;
	padding: 50px 0px;
	background-repeat: no-repeat;
	background-size: cover;
}

.gpu__content.author-ofthemonth img {
	width: 200px;
	height: 200px;
	border-radius: 50%;
}

.gpu__content.author-ofthemonth h2 {
	margin-top: unset;
	font-size: 36px;
	color: #ffffff;
}

.gpu__content.author-ofthemonth a {
	font-size: 15px;
	color: #ffffff;
	font-family: authorSemiBold;
}

.gpu__content.author-ofthemonth .author__wrapper {
	margin-left: 30px;
}

.gpu__content.author-ofthemonth a:hover {
	text-decoration: underline;
}

.gpu__content.author-ofthemonth .author.name {
	font-size: 34px;
	color: #ffffff;
}

.gpu__content.author-ofthemonth .author.desc {
	font-size: 16px;
	color: #ffffff;
	font-family: authorMedium;
	margin: 15px 0px;
	line-height: 18px;
}

.gpu__content.author-ofthemonth .row {
	max-width: unset;
}

footer {
	margin-top: 60px;
	border-top: 1px solid #EDEDED;
}

footer .wrapper__main-footer .row {
	max-width: unset;
}

footer .wrapper__main-footer {
	padding-top: 40px;
}

.wrapper__secondary-footer {
	background: #F5F1F1;
	padding: 30px 0px;
	margin-top: 40px;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.flex-link-footer {
	display: flex;
	flex-wrap: wrap;
}

.footer__icon {
	color: #009688;
}


.footer__desc {
	font-size: 18px;
	font-family: authorMedium;
	color: #333333;
	padding-left: 20px;
	line-height: 22px;
}

.footer__desc.telp:hover {
	text-decoration: underline;
}

a .footer__desc.email:hover {
	text-decoration: underline;
}

.footer__detail {
	margin: 20px 0px;
}

.footer__list {
	font-size: 20px;
	color: #333333;
	font-family: authorSemiBold;
	margin-bottom: 10px;
	text-transform: uppercase;
	padding-left: 20px;
}

.footer__link-list-item {
	margin-bottom: 10px;
	padding-left: 20px;
}

.footer__link-list-item a {
	font-size: 16px;
	color: #333333;
	font-family: authorMedium;
	text-transform: uppercase;
}

.footer__link-list-item a:hover {
	color: #009688;
}

.footer__icon-secondary {
	color: #501214;
	padding-right: 20px;
}

.wrapper__footer-icon-secondary {
	padding-left: 30px;
}

.footer__email {
	background-color: #F5F5F5 !important;
	border-radius: 5px 0px 0px 5px !important;
	border: unset !important;
	box-shadow: unset !important;
	padding: 15px !important;
	height: 44px !important;
	margin-bottom: 35px !important;
	color: #000000 !important;
}

.btn.btn__footer-email {
	border-radius: 0px 5px 5px 0px !important;
	width: 120px !important;
	height: 44px !important;
	background-color: #009688;
	color: #ffffff;
}

.btn.btn__footer-email:hover {
	background-color: #098377;
}

.wrapper__footer-email {
	padding-left: 20px;
}

.secondary-footer__desc {
	font-size: 16px;
	color: #635858;
}

.secondary-footer__link {
	margin-left: 30px;
}

.secondary-footer__link a {
	color: #501214;
	font-family: authorMedium;
	font-size: 18px;
}

.secondary-footer--wrap {
	flex-wrap: wrap;
}

.dropdown-menu[data-bs-popper] {
	left: unset;
}

.dropdown-toggle::after {
	position: relative;
	top: 2px;
}


/* DETAIL BUKU */

#more {
	display: none;
}

aside.sharebar {
	position: sticky;
	left: 5%;
	top: 15%;
	float: left;
	z-index: 999;
}

aside.sharebar.custom--position {
	position: sticky;
	left: 1%;
	top: 35%;
	float: left;
	z-index: 999;
}

.aside__label {
	font-size: 16px;
	color: #222222;
	margin-bottom: 10px;
	text-align: center;
}

aside .aside__icon {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin-bottom: 20px;
}

aside .aside__icon i {
	position: relative;
	top: 20%;
	color: #ffffff;
}

aside .aside__icon.aside__icon.facebook i {
	left: 34%;
}

aside .aside__icon.aside__icon.twitter i {
	left: 28%;
	top: 23%;
}

aside .aside__icon.aside__icon.gmail i {
	left: 25%;
}

aside .aside__icon.aside__icon.whatsapp i {
	left: 28%;
}

aside .aside__icon.aside__icon.linkedin i {
	left: 29%;
}

aside .aside__icon.aside__icon.mail i {
	left: 29%;
}

aside .aside__icon.facebook {
	background: #3A589B;
}

aside .aside__icon.twitter {
	background: #1CB7EB;
}

aside .aside__icon.gmail {
	background: #E3411F;
}

aside .aside__icon.linkedin {
	background: #007AB9;
}

aside .aside__icon.whatsapp {
	background: #26d367;
}

aside .aside__icon.mail {
	background: #BBBBBB;
}

.wrapper__breadcrumb {
	margin-top: 100px;
}

.wrapper__breadcrumb .breadcrumb-item {
	background: #F4F4F4;
	border-radius: 3px;
	margin-right: 10px;
	padding: 5px 12px;
}

.wrapper__breadcrumb .breadcrumb-item a {
	color: #9E8E8E;
	font-size: 14px;
	font-family: authorMedium;
}

.wrapper__breadcrumb .breadcrumb-item.active {
	background: #E20A15;
	border-radius: 3px;
	margin-right: 10px;
	padding: 5px 12px;
	color: #FFFFFF;
	font-size: 14px;
	font-family: authorMedium;
}

.wrapper__breadcrumb .breadcrumb-item+.breadcrumb-item::before {
	content: unset;
}

.gpu__content.detail-buku {
	margin: 40px 150px;
}

.gpu__content.detail-buku .dropdown-item {
	height: 30px;
}

.detail-buku__img {
	margin-right: 30px;
}

.detail-buku__img img {
	max-width: 320px;
	max-height: 480px;
}

.detail-buku__category {
	font-size: 20px;
	color: #E20A15;
	font-family: authorSemiBold !important;
	text-transform: uppercase;
}

.detail-buku__container h1 {
	margin: 15px 0px;
}

.detail-buku__container h2 {
	margin: unset;
}

.detail-buku__title {
	font-size: 30px;
	color: #000000;
	font-family: authorMedium !important;
	line-height: 40px;
	margin: 5px 0px !important;
}

.detail-buku__author {
	font-size: 18px;
	color: #333333;
	margin: unset;
}

.detail-buku__author span {
	font-size: 18px;
	color: #1C79E5;
	font-family: authorMedium;
}

.detail-buku__tabs {
	margin: 20px 0px;
}

.detail-buku__tabs .tab-content p {
	color: #444444;
	font-size: 16px;
	line-height: 22px;
}

.detail-buku__tabs .tab-content .tab-pane .tab-readmore{
	position: relative;
    height: 100px;
    overflow: hidden;
    -webkit-transition: height .3s ease;
    -moz-transition: height .3s ease;
    -ms-transition: height .3s ease;
    -o-transition: height .3s ease;
    transition: height .3s ease;
}
/* .detail-buku__tabs .tab-content .tab-pane .tab-readmore .fog{
	position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
	display: block;
    background: #eff8fd;
    background: -moz-linear-gradient(180deg,rgba(239,248,253,0) 0,rgba(239,248,253,.701185) 50%,#eff8fd 100%);
    background: -webkit-linear-gradient(180deg,rgba(239,248,253,0),rgba(239,248,253,.701185) 50%,#eff8fd);
    background: linear-gradient(180deg,rgba(239,248,253,0),rgba(239,248,253,.701185) 50%,#eff8fd);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eff8fd",endColorstr="#eff8fd",GradientType=1);
	transition: all .3s ease;
} */
.detail-buku__tabs .tab-content .tab-pane .tab-readmore:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: #eff8fd;
    background: -moz-linear-gradient(360deg,#fff 25%,transparent 100%);
    background: -webkit-linear-gradient(360deg,#fff 25%,transparent);
    background: linear-gradient(360deg,#fff 25%,transparent);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="transparent",endColorstr="transparent",GradientType=1);
	-webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
	transition: all .3s ease;
}
.detail-buku__tabs .tab-content .tab-pane .no-after:after{
	display: none;
	-webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
	transition: all .3s ease;
}
/* .detail-buku__tabs .tab-content .tab-pane .tab-readmore-show{
    height: auto;
} */
/* .detail-buku__tabs .tab-content p.text--transparent {
	-webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
	mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}

.detail-buku__tabs .tab-content p.text--transparent.non {
	-webkit-mask-image: unset;
	mask-image: unset;
} */

.wrapper__btn-readmore {
	width: 100%;
	background: #EEEEEE;
	text-align: center;
	cursor: pointer;
	padding: 5px 0px;
	border-radius: 5px;
	margin-bottom: 30px;
}

.detail-buku__container {
	width: 500px;
}

.detail-buku__tabs .nav.nav-pills {
	margin-left: 0;
}

.detail-buku__tabs .nav.nav-pills .nav-item {
	margin-right: 10px;
}

.detail-buku__tabs .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
	color: #ffffff;
	background-color: #009688;
	font-size: 16px;
	font-family: authorMedium;
}

.detail-buku__tabs .nav-pills .nav-link {
	background: #EEEEEE;
	color: #333333;
	font-size: 16px;
	font-family: authorMedium;
}

.icon-love-red {
	color: #FB4E4E;
	font-size: 16px;
	position: relative;
	top: 2px;
	margin-right: 2px;
}

.details-label {
	font-size: 16px;
	color: #333333;
	font-family: authorMedium;
}

.details-value span {
	position: absolute;
	left: 20%;
}

.details-value {
	font-size: 16px;
	color: #333333;
	font-family: authorMedium;
}

.details-tags-label {
	font-family: authorSemiBold;
	font-size: 16px;
	color: #333333;
	margin-bottom: 5px;
}

.details-tags-value {
	background: #51C2F3;
	border-radius: 20px;
	padding: 5px 15px;
	margin: 5px 5px 5px 0px;
}

.details-tags-value:hover {
	background: #259ED2;
}

.details-tags-value a {
	font-family: authorMedium;
	font-size: 15px;
	color: #FFFFFF;
}

.details-tags-value a:hover {
	color: #ffffff;
}

.detail-buku__button {
	width: 100%;
	color: #ffffff;
	margin-bottom: 10px;
	font-size: 18px;
}

.detail-buku__button.primary--custom {
	background: #FF6C2B;
}

.detail-buku__button.primary--custom:hover {
	background: #E15C21;
	color: #ffffff;
}

.detail-buku__button.secondary--custom {
	background: #3650DA;
}

.detail-buku__button.secondary--custom:hover {
	background: #213CCA;
	color: #ffffff;
}

.detail-buku__review .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
	background: #ffffff;
	border-bottom: 3px solid #E20A15;
	font-size: 20px;
	color: #333333;
	font-family: authorMedium;
	border-radius: 0px;
}

.detail-buku__review .nav-pills .nav-link {
	background: #ffffff;
	font-size: 20px;
	color: #666666;
	font-family: authorMedium;
	border-radius: 0px;
}

.detail-buku__review .nav-item {
	margin-right: 30px;
}

.detail-buku__review .tab-content {
	border: 1px solid #DFDFDF;
	border-radius: 3px;
	margin: 20px 0px;
}

.detail-buku__review .tab-content .tab-pane .btn.btn-primary:hover {
	background: #009688 !important;
	border-color: unset;
}

.review__label-first {
	text-align: center;
	font-family: authorMedium;
	font-size: 16px;
	color: #333333;
}

.review__label-second {
	text-align: center;
	font-size: 16px;
	color: #333333;
	margin-bottom: 20px;
}

.review-book {
	width: 100%;
}

.detail-buku__review .btn.btn-primary {
	font-size: 16px;
	font-family: authorMedium;
}

.detail-buku__review .btn.btn-danger {
	font-size: 16px;
	font-family: authorMedium;
}

.gpu__content.related-books {
	margin: 0 100px;
}

.gpu__content.related-books h2 {
	font-size: 36px;
	margin: unset;
	color: #333333;
}

.gpu__content.related-books h3 {
	font-size: 15px;
	margin: 20px 0px;
	color: #000000;
	width: 300px;
}

.related-books__lines {
	border: 1px solid #E20A15;
	position: relative;
	top: 45%;
	width: 100%;
}

.related-books__category:hover {
	text-decoration: underline;
}

.related-books__author:hover {
	text-decoration: underline;
}

.gpu__content.related-books .related-books__category {
	color: #009688;
	font-size: 15px;
	font-family: authorMedium;
	margin-bottom: 15px;
}

.gpu__content.related-books .related-books__title {
	color: #000000;
	font-size: 20px;
	font-family: authorMedium;
	margin-bottom: 15px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 22px;
	width: 170px;
}

.gpu__content.related-books .related-books__title:hover {
	color: #9B1A1A;
}

.gpu__content.related-books .related-books__author {
	color: #1C79E5;
	font-size: 16px;
	font-family: authorMedium;
}

.related-books__detail-book {
	margin-top: 15px;
	margin-bottom: 30px;
}

.wrapper__reviews {
	display: flex;
	padding: 40px 20px;
	border-bottom: 1px solid #DFDFDF;
}

.wrapper__reviews-book {
	display: flex;
	padding: 40px 20px;
}

.wrapper__reviews img {
	max-width: 40px;
	height: 40px;
	border-radius: 50%;
}

.wrapper__reviews-book img {
	max-width: 40px;
	height: 40px;
	border-radius: 50%;
}

.wrapper__reviews .reviews__detail {
	margin-left: 30px;
}

.wrapper__reviews-book .reviews-book__detail {
	margin-left: 30px;
	width: 100%;
}

.wrapper__reviews .reviews__name {
	color: #000000;
	font-size: 16px;
}

.wrapper__reviews-book .reviews-book__name {
	color: #000000;
	font-size: 16px;
	text-align: left;
}

.review-book .form-control {
	width: 100% !important;
	height: 120px !important;
	background: #E8E8E8 !important;
	border-radius: 3px !important;
	border: unset !important;
	box-shadow: unset !important;
	margin-bottom: 20px !important;
}

.wrapper__reviews .reviews__date {
	color: #757575;
	font-size: 16px;
	padding-bottom: 10px;
}

.wrapper__reviews .reviews__comment {
	color: #686868;
	font-size: 16px;
}

.wrapper__reviews .reviews__laporkan {
	text-align: right;
}

.wrapper__reviews .reviews__laporkan a {
	color: #949494;
	font-size: 16px;
	padding-top: 10px;
	font-family: authorMedium;
}

.wrapper__reviews .reviews__laporkan a:hover {
	color: #ED1C24;
	font-family: authorMedium;
}

.wrapper__reviews .reviews__laporkan a:focus {
	color: #ED1C24;
	font-family: authorMedium;
}

.wrapper__reviews-pagination {
	margin: 20px;
	display: flex;
	justify-content: center;
}

.wrapper__reviews-pagination .page-item.active .page-link {
	background: #2CC0B3;
	color: #ffffff;
	border-color: unset;
	font-size: 16px;
}

.wrapper__reviews-pagination .page-item .page-link {
	color: #B5B5B5;
	background: #F4F4F4;
	font-size: 16px;
	border: unset;
}

.wrapper__reviews-pagination .page-item .page-link:focus {
	background: #2CC0B3;
	color: #ffffff;
}



.wrapper__about-author {
	display: flex;
	padding: 40px 20px;
}

.wrapper__about-author img {
	max-width: 100px;
	height: 100px;
}

.wrapper__about-author .about-author__detail {
	margin-left: 30px;
}

.wrapper__about-author .about-author__name {
	color: #000000;
	font-size: 16px;
}

.wrapper__about-author .about-author__date {
	color: #757575;
	font-size: 16px;
	padding-bottom: 10px;
}

.wrapper__about-author .about-author__comment {
	color: #686868;
	font-size: 16px;
}

.author-books__label {
	font-size: 20px;
	color: #333333;
	padding: 30px;
}

.wrapper__author-books {
	margin-bottom: 50px;
	padding-bottom: 30px;
	border-bottom: 1px solid #DFDFDF;
}

.wrapper__author-books h2 {
	font-size: 20px;
	color: #333333;
	font-family: authorMedium !important;
	margin-top: unset;
	margin-bottom: 14px;
	line-height: 22px;
}

.wrapper__author-books h2:hover {
	color: #F06565;
	transition: .5s;
}

.author-books__date {
	font-size: 16px;
	color: #333333;
	font-family: authorMedium;
}

.wrapper__author-books-list {
	display: flex;
	align-items: center;
}

.author-books__detail {
	padding-left: 20px;
}

.books__more {
	background: #2CC0B3;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	color: #FFFFFF;
	font-size: 20px;
	text-align: center;
}

.wrapper__author-books .row {
	padding-left: 20px;
}

/* DETAIL UPDATES PROMO & EVENTS */

.gpu__content.updates-promo {
	margin: 30px 0px
}

.update-promo__img img {
	max-width: 400px;
	height: 100%;
}

.updates-promo__detail {
	margin-left: 30px;
}

.gpu__content.updates-promo .updates-promo__detail .detail-promo__category {
	margin: unset;
	font-size: 20px;
	color: #E20A15;
	font-family: authorSemiBold !important;
	text-transform: uppercase;
}

.gpu__content.updates-promo .updates-promo__detail .detail-promo__title {
	font-size: 30px;
	color: #000000;
	font-family: authorMedium !important;
	margin: 15px 0px;
	line-height: 30px;
}

.gpu__content.updates-promo .updates-promo__detail .detail-promo__desc {
	font-size: 16px;
	color: #444444;
	font-family: authorMedium !important;
	margin-bottom: 20px;
}

.gpu__content.updates-promo .updates-promo__detail ul.detail-promo__desc {
	margin-top: 30px;
}

.promo-desc__label {
	font-size: 20px;
	color: #333333;
	font-family: authorSemiBold !important;
	width: 40%;
}

.promo-desc__value {
	font-size: 16px;
	color: #333333;
	width: 100%;
}

.updates-promo__item {
	border-top: 1px solid #EEEEEE;
	padding: 20px 0px;
}

.updates-promo__list {
	margin: 30px 0px;
}

.updates-promo__list.custom-lh {
	line-height: 25px;
}

.promo-desc__value .value--bold {
	font-family: authorSemiBold !important;
}

.value--margintop {
	margin-bottom: 20px;
}

.updates-promo.masa-periode {
	background: #F3FAFB;
	border-radius: 5px;
	padding: 20px 0px;
}

.masa-periode__label {
	font-size: 20px;
	color: #333333;
	font-family: authorSemiBold !important;
}

.masa-periode__value {
	font-size: 16px;
	color: #ED1C24;
	font-family: authorMedium !important;
}

.masa-periode__wrapper {
	margin-left: 30px;
}


.all-updates__wrapper {
	/* width: 100%; */
	margin: 30px;
}

.all-updates__search {
	position: relative;
}

.form-control.custom--input.width {
	width: 100%;
}

.search-icon--updates {
	position: absolute;
	top: 24%;
	right: 2%;
	width: 18px;
	height: 18px;
}

.all-updates__list-wrapper {
	border: 1px solid #DFDFDF;
	border-radius: 5px;
	padding: 20px;
	margin: 30px 0px;
}

.all-updates__label {
	color: #333333;
}

.all-updates__label.bold {
	font-size: 20px;
	font-family: authorSemiBold;
}

.all-updates__label a {
	color: #333333;
}

.all-updates__label.regular a {
	font-size: 15px;
}

.all-updates__label.regular a:hover {
	color: #9B1A1A;
}

.all-updates__item {
	display: flex;
	margin-bottom: 20px;
}

.all-updates__item img {
	max-width: 110px;
}

.all-updates__item-value h2 {
	color: #333;
    font-family: authorMedium;
    font-size: 18px;
    margin: unset;
    line-height: 21px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.all-updates__item-value h2:hover {
	color: #9B1A1A;
}

.all-updates__item-value p {
	font-size: 16px;
	color: #836B6B;
}

.all-updates__item-value {
	padding-left: 15px;
}


.line-gray {
	border: 1px solid #EDEDED;
	width: 100%;
	margin: 20px 0px;
}

.gpu__content.other-promo .row {
	max-width: unset;
}

.gpu__content.other-promo h2 {
	font-size: 36px;
	font-weight: 400;
	color: #E20A15;
	margin-top: unset;
	margin-bottom: 20px;
	text-transform: uppercase;
}

.gpu__content.other-promo a {
	font-size: 15px;
	font-weight: 400;
	color: #333333;
}

.gpu__content.other-promo a:hover {
	color: #9B1A1A;
}

.gpu__content.updates-promo .btn.btn-primary {
	margin-bottom: 10px;
	font-size: 18px;
	/* width: 212px; */
}

.dropdown-menu.custom--width {
	width: 313px;
	border-radius: 0px 0px 5px 5px;
	border: unset;
	box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.15);
}


/* REGISTER, CREATE NEW ACCOUNT, LOGIN */

.modal h5 {
	margin: unset;
}

.gpu__content.register {
	text-align: center;
	margin: 30px 0px;
}

.btn.btn-google {
	color: #333333;
	font-family: authorMedium;
	font-size: 15px;
	background: #ffffff;
	border: 1px solid #D6D6D6;
	border-radius: 7px !important;
	width: 80%;
	margin-bottom: 20px;
}

.btn.btn-google img {
	width: 22px;
	margin-right: 10px;
}

.btn.btn-myvalue {
	color: #333333;
	font-family: authorMedium;
	font-size: 15px;
	background: #ffffff;
	border: 1px solid #D6D6D6;
	border-radius: 7px !important;
	width: 80%;
	margin-bottom: 20px;
}

.btn.btn-myvalue img {
	width: 82px;
	height: 12px;
	margin-right: 10px;
}

.btn.btn-facebook {
	color: #ffffff;
	font-family: authorMedium;
	font-size: 15px;
	background: #1976D2;
	border-radius: 7px !important;
	width: 80%;
	margin-bottom: 20px;
}

.btn.btn-facebook i {
	margin-right: 10px;
	position: relative;
	top: 2px;
	font-size: 20px;
}

.register__label.bold {
	color: #333333;
	font-family: authorMedium;
	font-size: 22px;
	margin-top: 30px;
	margin-bottom: 15px;
}

.register__label.medium a {
	color: #333333;
	font-size: 15px;
	margin-bottom: 30px;
}

.register__label.regular {
	color: #333333;
	font-size: 14px;
	margin-bottom: 30px;
}

.register__label a {
	color: #1C79E5;
}

.register__label.regular-gray {
	color: #9E9E9E;
	font-size: 15px;
	margin-bottom: 30px;
}

.register__label.regular-gray span {
	color: #009688;
	font-family: authorMedium;
}

.container.custom-checkbox {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox--custom input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkmark {
	position: absolute;
	left: 2%;
	bottom: 13%;
	height: 20px;
	width: 20px;
	background-color: #ffffff;
	border: 2px solid #009688;
	border-radius: 5px;
}

.checkbox--custom:hover input~.checkmark {
	background-color: #ffffff;
}

.checkbox--custom input:checked~.checkmark {
	background-color: #009688;
}

.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.checkbox--custom input:checked~.checkmark:after {
	display: block;
}

.checkbox--custom .checkmark:after {
	left: 5px;
	top: 2px;
	width: 6px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.form-control.register {
	width: 80%;
	height: 45px;
	box-shadow: unset;
	border-radius: 7px !important;
}

.btn.btn-primary--custom.register {
	width: 80%;
	border-radius: 7px !important;
}

.register__recaptcha {
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
}

.register__label.medium.blue a {
	color: #0C68DA !important;
	font-family: authorMedium;
}

.register__label.regular-gray.custom a {
	color: #009688;
	font-family: authorMedium;
}

/* MY PROFILE */
.gpu__content.my-profile {
	display: flex;
	margin: 40px 0px;
}

.wrapper__my-profile-sidebar {
	width: 25%;
	margin-right: 30px;
}

.wrapper__my-profile-tabs {
	border: 1px solid #DFDFDF;
	border-radius: 5px;
	width: 100%;
	display: flex;
	align-items: center;
	padding-left: 40px;
}

.wrapper__my-profile-nav {
	margin: 30px 0px;
}

.wrapper__my-profile-nav .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
	color: #009688;
	background-color: #ffffff;
	border: 1px solid #009688;
	font-family: authorMedium;
}

.wrapper__my-profile-nav .nav-pills .nav-link {
	color: #333333;
	border: 1px solid #D6D6D6;
	font-family: authorMedium;
}

.wrapper__my-profile-nav .nav-pills .nav-link.profile-logout {
	color: #FB4E4E;
	font-family: authorMedium;
	border: unset;
}

.wrapper__my-profile-label .label__primary {
	color: #333333;
	font-size: 13px;
	font-family: authorMedium;
	text-align: center;
}

.wrapper__my-profile-label .label__secondary {
	color: #333333;
	font-size: 18px;
	font-family: authorMedium;
	text-align: center;
}

.my-profile__img {
	display: flex;
	justify-content: center;
}

.wrapper__tabs-content {
	width: 100%;
	margin: 40px 0px;
}

.wrapper__tabs-content label {
	color: #333333;
	font-size: 16px;
	font-family: authorMedium;
	margin-bottom: 5px;
}

.wrapper__tabs-content input {
	background: #E8E8E8;
	border: unset;
	border-radius: 5px !important;
	font-size: 14px !important;
	color: #333333 !important;
	width: 400px !important;
	margin-bottom: 0px !important;
	box-shadow: unset !important;
}

.my-profile__profile-detail {
	margin: 20px 0px;
	position: relative;
}

.form-control.is-invalid, .was-validated .form-control:invalid{
	background-image: none !important;
}

.input-password-wrapper{
	width: fit-content;
}

.position-eye {
	position: absolute;
	/* bottom: 12%; */
	right: 2%;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
}

.passwordeye.hidden {
	display: none;
}

.my-profile__label {
	color: #000000;
	font-size: 16px;
	font-family: authorSemiBold;
	text-transform: uppercase;
}

.wrapper__tabs-content .btn.btn-login--custom {
	width: 400px;
	color: #FFFFFF;
	background: #009688;
	margin-bottom: 0px;
}

.gpu__content.my-profile .wishlist-books__category {
	color: #009688;
	font-size: 15px;
	font-family: authorMedium;
}

.gpu__content.my-profile .wishlist-books__category:hover {
	text-decoration: underline;
}

.gpu__content.my-profile .wishlist-books__title {
	color: #000000;
	font-size: 20px;
	font-family: authorMedium;
	margin: 15px 0px;
}

.gpu__content.my-profile .wishlist-books__title:hover {
	color: #9B1A1A;
}

.gpu__content.my-profile .wishlist-books__author {
	color: #1C79E5;
	font-size: 16px;
	font-family: authorMedium;
}

.gpu__content.my-profile .wishlist-books__author:hover {
	text-decoration: underline;
}

.wishlist-books__detail-book {
	margin-top: 15px;
	margin-bottom: 50px;
}

.undo-wishlist {
	background: #EEEEEE;
	color: #333333;
	font-size: 16px;
	font-family: authorMedium;
	border-radius: 5px;
	text-align: center;
	width: 100%;
	margin-top: 20px;
}

.wishlist-books {
	width: 220px;
}

.wrapper__tabs-content .row.custom--margin {
	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) * -.5);
	margin-left: calc(var(--bs-gutter-x) * -.5);
}

.my-profile__img>input {
	display: none;
}

.icon-upload {
	position: absolute;
	bottom: 4%;
	left: 44%;
}

/* LIST BUKU */
.gpu__content.list-buku {
	display: flex;
	margin-top: 50px;
}

.gpu__content.list-buku .row.list-buku-row img {
	width: 170px;
	height: 100%;
}

.gpu__content.list-buku .wrapper__sidebar-category h2 {
	font-size: 18px;
	color: #000000;
	margin: 0;
	font-family: authorMedium !important;
	margin-left: 1.4rem;
	margin-bottom: 30px;
	padding-top: 12px;
}

.wrapper__list-buku {
	width: 100%;
	margin-left: 60px;
}

.wrapper__list-buku h2 {
	font-size: 30px;
	color: #000000;
	margin: 0;
	font-family: authorMedium !important;
}

.list-group.list-buku {
	width: 90%;
}

.list-group.list-buku .list-group-item {
	border: unset;
	padding: 5px 0px 5px 4px;
}

.list-group.list-buku .list-group-item a {
	font-size: 16px;
	color: #333333;
}

.list-group.list-buku .list-group-item.custom a {
	line-height: 18px;
}

.list-group.list-buku .list-group-item a:hover {
	color: #009688;
}

.list-group.list-buku .list-group-item.active-custom a {
	font-size: 16px;
	color: #009688;
}

.wrapper__sidebar-category {
	width: 20%;
}

.list-custom--padding li {
	margin-left: 20px;
}

.btn.btn-download {
	border-radius: 5px !important;
	background-color: #009688;
	color: #ffffff;
	margin-left: 20px;
	margin-bottom: 0;
	font-size: 16px;
	width: 100%;
}

.btn.btn-download:hover {
	background-color: #098377;
}

.list-buku__datetime {
	background: #F8F8F8 !important;
	border-radius: 5px !important;
	margin-bottom: 0 !important;
}

.wrapper__list-buku-datetime {
	margin-left: 20px;
}

.text-sort {
	font-size: 16px;
	color: #333333;
	margin-right: 10px;
}

.wrapper__list-buku .form-select {
	background-color: #F8F8F8;
	margin-bottom: 0;
}

.book__wrapper-custom {
	position: relative;
	width: 170px;
	margin-top: 15px;
	margin-bottom: 30px;
	height: 140px;
}

.gpu__content.list-buku .carousel__category {
	color: #009688;
	font-size: 15px;
	font-family: authorMedium;
	margin-bottom: 15px;
}

.gpu__content.list-buku .carousel__title {
	color: #000000;
	font-size: 20px;
	font-family: authorMedium;
	margin-bottom: 15px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 22px;
	width: 150px;
}

.gpu__content.list-buku .carousel__title:hover {
	color: #9B1A1A;
}

.gpu__content.list-buku .carousel__author {
	color: #1C79E5;
	font-size: 16px;
	font-family: authorMedium;
	line-height: 18px;
}


.wrapper__list-categories-pagination {
	margin-left: 60px;
}

.wrapper__list-categories-pagination .page-item.active .page-link {
	background: #ffffff;
	color: #009688;
	border: 1px solid #009688;
	font-size: 16px;
}

.wrapper__list-categories-pagination .page-item .page-link {
	color: #ffffff;
	background: #009688;
	font-size: 16px;
	border: unset;
}

.wrapper__list-categories-pagination .page-item .page-link:focus {
	background: #2CC0B3;
	color: #ffffff;
}

.fas.fa-arrow-right.custom--fontsize {
	font-size: 10px;
}

.content-filter {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.gpu__content.list-buku .bestselling__wrapper {
	margin: 40px 0px;
}

.col-custom-5 {
	margin-right: 60px;
}

.wrapper__list-buku .row {
	align-items: flex-end;
	margin-top: 40px;
	/* margin-left: 50px; */
	max-width: unset;
}

.wrapper__list-buku .row .col-lg-3.col-md-6.col-sm-12 {
	margin-bottom: 40px;
}

/* LIST AUTHORS */


.wrapper__list-authors {
	margin-top: 30px;
	margin-left: 20px;
}

.wrapper__list-authors .form-select {
	background-color: #F8F8F8;
	margin-bottom: 0;
	width: 240px;
	border-radius: 5px;
}

.wrapper__list-authors .wrapper__form-select {
	margin-right: 20px;
}

.list-authors__search {
	background-color: #F8F8F8 !important;
	width: 240px !important;
	border: 1px solid #ced4da !important;
	border-radius: 5px !important;
	box-shadow: unset !important;
	color: #000000 !important;
}

.search-icon-custom {
	position: absolute;
	top: 17%;
	right: 6%;
	width: 18px;
	height: 18px;
}

.list-authors__label {
	font-size: 20px;
	font-family: authorSemiBold !important;
	color: #E20A15;
	text-transform: uppercase;
	margin: 0;
}

.list-authors__label~img {
	width: 200px;
	height: 200px;
	border-radius: 50%;
}

.wrapper__item-authors {
	margin-bottom: 40px;
}

.gpu__content.list-authors .wrapper__item-authors .wrapper__detail-authors img {
    /* height: 180px; */
    /* width: 132px; */
	height: 200px;
	width: 100%;
    object-fit: cover;
    object-position: top center;
}

.row.max-width--unset {
	max-width: unset;
}

.item-authors__name:hover {
	text-decoration: underline;
}

.img__allstars {
	width: 166px;
	height: 166px;
	border-radius: 50%;
}

.item-authors__name {
	font-size: 18px;
	color: #1C79E5;
	font-family: authorSemiBold;
	margin: 10px 0px;
}

.item-authors__book-title {
	font-size: 20px;
	color: #000000;
	font-family: authorMedium;
	margin-bottom: 10px;
	line-height: 22px;
	height: 65px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.item-authors__book-title:hover {
	color: #9B1A1A;
}

.item-authors__socmed:hover {
	background: #ffffff;
}

.item-authors__socmed {
	display: flex;
	align-items: center;
	padding: 5px 10px;
	background: #F5F5F5;
	border: 1px solid #D9D9D9;
	border-radius: 5px;
	margin-bottom: 10px;
	position: relative;
}

.item-authors__socmed img {
	position: relative;
	top: 1px;
}

.socmed__label {
	color: #333333;
	font-size: 17px;
	font-family: authorMedium;
	margin-left: 5px;
}

.gpu__content.list-authors .row {
	max-width: unset;
}

.wrapper__list-authors-label {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 20px 0px;
}

.list-authors__label-more {
	color: #009688;
	font-size: 17px;
	font-family: authorMedium;
}

.wrapper__detail-authors {
	display: flex;
}

.wrapper__search-authors {
	position: relative;
}


/* DETAIL AUTHOR */
.gpu__content.detail-authors .wrapper__detail-authors {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.gpu__content.detail-authors {
	margin-top: 40px;
}

.gpu__content.detail-authors .form__search {
	width: 100%;
}

.wrapper__sidebar-author .list-authors__label {
	margin: 40px 0px;
}

.wrapper__sidebar-author .item-authors__name.center {
	margin: 10px 0px;
	text-align: center;
}

.wrapper__sidebar-author .item-authors__name {
	margin: 10px 0px;
}

.other-author__socmed {
	width: 38px;
	height: 38px;
	background: #F5F5F5;
	border: 1px solid #D9D9D9;
	border-radius: 5px;
	margin: 0px 5px;
	text-align: center;
}

.other-author__socmed:hover {
	background: #FFFFFF;
}

.wrapper__sidebar-author .other-author__img img {
	height: 100%;
}

.other-author__img>img {
	width: 80px;
	height: 80px !important;
	border-radius: 50%;
}

.other-author__socmed img {
	width: 20px;
}

.wrapper__other-author {
	margin-left: 10px;
}

.other-author__img {
	margin-bottom: 20px;
}

.item-authors__name.custom--padding {
	margin: 0px 0px 5px 0px;
}

.row.author .img-author {
	width: 166px;
	height: 166px;
	border-radius: 50%;
	margin-bottom: 10px;
}

.content__detail-author {
	margin-right: 60px;
}

.gpu__content.detail-authors .carousel__category {
	color: #009688;
	font-size: 15px;
	font-family: authorMedium;
	margin-bottom: 15px;
}

.gpu__content.detail-authors .carousel__title {
	color: #000000;
	font-size: 20px;
	font-family: authorMedium;
	margin-bottom: 15px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 22px;
}

.gpu__content.detail-authors .carousel__title:hover {
	color: #9B1A1A;
}

.gpu__content.detail-authors .carousel__author {
	color: #1C79E5;
	font-size: 16px;
	font-family: authorMedium;
	line-height: 18px;
}

.content__detail-author .wrapper__list-authors-label {
	margin-bottom: 40px;
}

.item-authors__desc {
	font-size: 16px;
	line-height: 22px;
	color: #333333;
}

.item-authors__desc span {
	font-family: authorSemiBold;
}


/* SEARCH RESULT */
.gpu__content.search-result .carousel__category {
	color: #009688;
	font-size: 15px;
	font-family: authorMedium;
	margin-bottom: 15px;
}

.gpu__content.search-result .carousel__title {
	color: #000000;
	font-size: 20px;
	font-family: authorMedium;
	margin-bottom: 15px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 22px;
}

.gpu__content.search-result .carousel__title:hover {
	color: #9B1A1A;
}

.gpu__content.search-result .carousel__author {
	color: #1C79E5;
	font-size: 16px;
	font-family: authorMedium;
	line-height: 18px;
}

.gpu__content.search-result .wrapper__form-search {
	margin-top: 100px;
	display: flex;
}

.gpu__content.search-result .wrapper__form-select {
	margin-right: 10px;
	margin-bottom: 20px;
}

.gpu__content.search-result .form-select {
	background-color: #F8F8F8;
	border: 1px solid #E3E3E3;
	margin-bottom: 0;
	width: 240px;
	border-radius: 5px;
}

.gpu__content.search-result .form__search {
	width: 100%;
}

.form-control.custom--input-bg {
	background: #F8F8F8;
	border: 1px solid #E3E3E3;
	border-radius: 5px;
	font-size: 14px;
	color: #000000;
	width: 100%;
	margin-bottom: 0px;
	box-shadow: unset;
}

.search__result {
	font-size: 30px;
	color: #000000;
	font-family: authorMedium;
	margin-bottom: 30px;
}

.search__result span {
	color: #ED1C24;
}

.item-authors__name.custom--color {
	color: #000000;
}

.gpu__content.search-result .wrapper__detail-authors {
	display: flex;
	justify-content: center;
}


/* MERCHANDISE */
.merchandise__soldout {
	position: absolute;
	font-size: 15px;
	color: #E20A15;
	font-family: authorMedium;
}

.merchandise__title {
	font-size: 20px;
	font-family: authorMedium;
	color: #000000;
	padding-top: 20px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.merchandise__title:hover {
	color: #9B1A1A;
}

.merchandise__price {
	font-size: 24px;
	font-family: authorMedium;
	color: #009688;
	margin: 5px 0px;
}

.merchandise__desc {
	font-size: 16px;
	font-family: authorMedium;
	width: 100%;
	position: relative;
}

.merchandise__desc.mt-2 {
	height: 96px;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.row.merchandise__desc .col-6 {
	padding-left: 15px;
}

.merchandise__wrapper-custom {
	position: relative;
	margin-top: 15px;
	margin-bottom: 30px;
}

.merchandise__desc span {
	position: relative;
	right: 24%;
}

.wrapper__merchandise {
	width: 100%;
}

.wrapper__list-categories-pagination.custom--margin {
	margin-left: 160px;
}

/* LIST NEWS */
.row.custom--maxwidth {
	max-width: unset;
	margin-left: 0;
	align-items: unset;
}

.wrapper__list-buku .row.custom--maxwidth img {
	margin-right: 30px;
}

.wrapper__list-buku .row.custom--maxwidth .update-book.title-book {
	margin-top: 0;
	width: 600px;
	height: auto;
}

.wrapper__list-buku .row.custom--maxwidth .update-book.category {
	width: 100%;
}

.wrapper__list-buku .row.custom--maxwidth .update-book.preview {
	width: 400px;
	display: block;
	-webkit-line-clamp: unset;
	-webkit-box-orient: unset;
	overflow: unset;
	text-overflow: unset;
}

.dot-custom {
	padding: 0px 15px;
}


/* LIST REVIEWS */
.reviews-list__name {
	font-size: 20px;
	font-family: authorMedium;
	line-height: 22px;
	color: #000000;
}

.reviews-list__name:hover {
	color: #9B1A1A;
}

.reviews-list__desc {
	font-size: 16px;
	line-height: 22px;
	color: #000000;
	margin: 20px 0px;
}

.reviews-list__more {
	font-size: 16px;
	line-height: 22px;
	color: #009688;
}

.wrapper__reviews-list {
	display: flex;
}

/* CONTACT US */
.wrapper__form-contactus {
	width: 100%;
	margin-right: 40px;
}

.wrapper__contactus {
	display: flex;
	margin-left: 60px;
}

.gpu__content.contact-us h2 {
	font-size: 30px;
	color: #000000;
	font-family: authorMedium !important;
	margin: 0;
	margin-bottom: 25px;
}

.wrapper__form-contactus input {
	background: #E8E8E8 !important;
	border-radius: 3px !important;
	border: unset !important;
	box-shadow: unset !important;
	margin-bottom: 20px !important;
	color: #000000 !important;
}

.wrapper__form-contactus textarea {
	background: #E8E8E8 !important;
	border-radius: 3px !important;
	border: unset !important;
	box-shadow: unset !important;
}

.wrapper__form-contactus label {
	font-size: 16px !important;
	color: #000000 !important;
	margin-bottom: 5px;
	cursor: unset;
}

.contactus__maps {
	margin-top: 30px;
}

.btn.send-message {
	width: 202px;
	height: 45px;
	border-radius: 5px;
	background: #009688;
	color: #ffffff;
	font-size: 16px;
	margin-top: 30px;
	font-family: authorMedium;
}

.btn.send-message .fas.fa-arrow-right {
	font-size: 12px;
	padding-left: 5px;
}

.btn.send-message:hover {
	background: #098377;
}

/* FAQ */

.wrapper__faq {
	margin-left: 60px;
}

.wrapper__faq .title-faq {
	font-size: 30px;
	color: #000000;
	font-family: authorMedium !important;
	margin: 0;
	margin-bottom: 25px;
}

.wrapper__faq h2 {
	margin: 0;
}

.accordion-button:hover {
	background: unset;
	color: #000000;
}

.accordion-button:focus {
	background: unset;
	color: #000000;
}

.wrapper__faq .accordion-item {
	margin-bottom: 20px;
	border: 1px solid #DDDDDD;
}

.wrapper__faq .accordion-item:not(:first-of-type) {
	border-top: 1px solid #DDDDDD;
}

.wrapper__faq .accordion-button:not(.collapsed) {
	color: #000000;
	background-color: #ffffff;
	margin-bottom: 0;
}

.wrapper__faq .accordion-item h2 button {
	color: #000000;
	font-size: 20px;
	font-family: authorMedium;
	line-height: 22px;
}

.wrapper__faq .accordion-item .accordion-body {
	font-size: 16px;
	color: #333333;
	line-height: 22px;
	padding: 20px 10px;
}

.wrapper__faq .wrapper__list-categories-pagination {
	margin-left: 0px;
}

.wrapper__faq .accordion-button::after {
	background-image: url('../images/icon-plus.svg');
}

.wrapper__faq .accordion-button:not(.collapsed)::after {
	background-image: url('../images/icon-minus.svg');
}

/* ABOUT US */
.about-us__detail p {
	font-size: 16px;
	color: #333333;
	line-height: 22px;
	margin-bottom: 15px;
}

.about-us__title {
	font-size: 30px;
	color: #000000;
	font-family: authorMedium !important;
	line-height: 30px;
	margin: 0;
	margin: 20px 0px;
}

.about-us__milestones h2 {
	font-size: 30px;
	color: #000000;
	font-family: authorMedium !important;
	line-height: 30px;
	margin: 0;
	margin: 20px 0px;
}

.gpu__content.about-us .owl-dots {
	display: none;
}

.about-us__milestones .milestones-line {
	border: 1px solid #333333;
	width: 100%;
	height: 1px;
	position: relative;
	top: 7px;
}

.milestones__years {
	padding: 20px 0px;
}

.owl-item .milestones__years::after {
	content: attr(data-year);
	font-size: 20px;
	color: #333333;
	line-height: 33px;
	font-family: authorMedium;
}

.owl-item.active--custom .milestones__years::after {
	font-size: 30px;
	color: #E20A15;
}

.milestones__desc {
	font-size: 15px;
	color: #333333;
	line-height: 20px;
}

.about-us__milestones .owl-item.active::after {
	content: '';
	position: absolute;
	width: 14px;
	height: 14px;
	background-color: #333333;
	top: 0px;
	border-radius: 30px;
	z-index: 9999;
}

.about-us__milestones .owl-item.active.active--custom::after {
	background-color: #E20A15;
}

.owl-carousel.milestones .owl-stage {
	padding-left: 0px !important;
}

.about-us__milestones {
	margin-left: 1.4rem;
	scroll-padding-top: 20px;
}

.about-us__ourteam {
	margin-left: 1.4rem;
}

.about-us__ourteam h2 {
	font-size: 30px;
	color: #000000;
	font-family: authorMedium !important;
	line-height: 30px;
	margin: 0;
	margin: 20px 0px;
}

.about-us__ourteam img {
	width: 90%;
}

.ourteam__navtabs .nav {
	margin-left: 0;
}

.ourteam__navtabs .nav-pills .nav-link.active {
	background-color: #009688;
	font-family: authorMedium;
	color: #ffffff;
}

.ourteam__navtabs .nav-pills .nav-link {
	color: #333333;
	font-family: authorMedium;
	background: #EEEEEE;
	border-radius: 5px;
}

.ourteam__navtabs .nav-pills .nav-item {
	margin-right: 10px;
}

.ourteam__name {
	font-size: 18px;
	font-family: authorSemiBold;
	color: #333333;
	line-height: 20px;
	margin: 10px 0px;
}

.ourteam__job {
	font-size: 15px;
	font-family: authorMedium;
	color: #333333;
	line-height: 17px;
	margin-bottom: 30px;
}

.ourteam__navtabs .row {
	max-width: unset;
}

.ourteam__navtabs .row>* {
	padding-left: 0;
}

/* INTERNATIONAL PARTNERSHIP */
.btn.send-message.custom--width {
	width: auto;
}

.btn.send-message.custom--margin {
	margin-top: 0px;
}

.btn.send-message.custom--width.hide-btn {
	display: none !important;
}

.btn.btn-primary.tulis-review.hide-btn {
	display: none;
}

.gpu__content.international-partnership h2 {
	margin: 20px 0px;
	font-size: 30px;
	font-family: authorMedium !important;
	color: #000000;
	line-height: 33px;
}

.international-partnership__title {
	width: 100%;
	text-align: center;
	font-size: 16px;
	color: #333333;
	line-height: 22px;
	margin-top: 10px;
	margin-bottom: 40px;
}

.international-partnership__title:hover {
	text-decoration: underline;
}

.international-partnership__img {
	width: 100%;
}

.gpu__content.international-partnership .wrapper__list-categories-pagination.custom--margin {
	margin-left: 0px;
}

.international-partnership__desc {
	font-size: 16px;
	color: #333333;
	line-height: 22px;
}

.international-partnership__desc a {
	color: #009688;
	text-decoration: underline;
}

.wrapper__form-joinus input {
	width: 420px !important;
	background: #E8E8E8 !important;
	border-radius: 3px !important;
	border: unset !important;
	box-shadow: unset !important;
	margin-bottom: 20px !important;
}

.wrapper__form-joinus label {
	font-size: 16px !important;
	color: #000000 !important;
	margin-bottom: 5px;
	cursor: unset;
}

.form-joinus__title {
	font-size: 20px;
	color: #E20A15;
	font-family: authorSemiBold;
	line-height: 22px;
	text-transform: uppercase;
	margin: 20px 0px;
}

/* CAREER */
.gpu__content.career h2 {
	margin: 20px 0px;
	font-size: 30px;
	font-family: authorMedium !important;
	color: #000000;
	line-height: 33px;
}

.gpu__content.career h3 {
	margin: 0px;
	font-size: 20px;
	font-family: authorMedium !important;
	color: #000000;
	line-height: 22px;
}

.gpu__content.career .btn.send-message {
	width: auto;
	margin: 0;
	margin-top: 20px;
}

.career__position-available {
	font-size: 18px;
	color: #333333;
	line-height: 20px;
}

.wrapper__career {
	border: 1px solid #DDDDDD;
	border-radius: 5px;
	padding: 20px;
	margin-bottom: 20px;
}

.career__desc {
	font-size: 16px;
	color: #333333;
	line-height: 22px;
}

/* ERROR 404 */
.error404__error404-1 {
	margin: 100px 0px;
}

.wrapper__error404 {
	text-align: center;
}

.error404__text-bold {
	color: #000000;
	font-size: 32px;
	text-transform: uppercase;
	font-family: authorMedium;
	margin: 40px 0px 5px 0px;
}

.error404__text-regular {
	color: #333333;
	font-size: 16px;
	margin-bottom: 100px;
}

.error404__text-link a {
	color: #0C68DA;
	font-size: 20px;
	font-family: authorMedium;
}

.error404__text-link a:hover {
	color: #0c69dad5;
}

.error404__logo-gpu img {
	width: 200px;
	height: 50px;
}

.error404__error404-1 img {
	width: 200px;
	height: 120px;
}

.error404__error404-2 img {
	width: auto;
	height: auto;
}

.category-author__thanone {
	line-height: 18px;
}

/* LIST PROMO */

.gpu__content.list-buku.container.promo-events .showing-promo-events {
	font-size: 30px;
	color: #000000;
	margin: 0;
	font-family: authorMedium !important;
}

.gpu__content.list-buku.container.promo-events .form-select {
	background-color: #F8F8F8;
	margin-bottom: 0;
}

/* SUBSCRIBE */
.subscribe__subtext {
	font-size: 16px;
	color: #333333;
	line-height: 19px;
	margin: 20px 0px;
}

.subscribe__back {
	font-family: authorSemiBold;
	font-size: 20px;
	color: #0C68DA;
	margin-top: 50px;
	line-height: 19px;
	position: relative;
}

.subscribe__icon {
	position: relative;
	bottom: 2px;
	right: 4px;
}

.title__blog {
	margin: 0;
	color: #333333;
	font-size: 30px;
	font-family: authorSemiBold !important;
}

.date__blog {
	color: #333333;
	font-size: 16px;
	margin-bottom: 20px;
}

/* MEDIA QUERY */
@media only screen and (max-width: 767px) {
	.bestselling__wrapper {
		flex-wrap: wrap;
		justify-content: center;
	}

	.carousel {
		margin-top: 10px;
	}

	.bg--custom {
		background: #ffffff;
		height: auto;
	}

	.gpu__content.new-release .carousel__title {
		width: 170px;
	}

	.col-custom-5 {
		margin-right: 20px;
	}

	.book__wrapper {
		position: relative;
		margin-right: 20px;
	}

	.gpu__content.bestselling .carousel__category {
		margin-bottom: 0px;
	}

	.gpu__content.bestselling .carousel__title {
		margin-bottom: 0px;
	}

	.book-quotes__img {
		display: none;
	}

	.gpu__content.bestselling {
		margin-bottom: 0px;
	}

	.update-book.preview {
		margin-bottom: 30px;
	}

	.author-ofthemonth--flex-wrap {
		flex-wrap: wrap;
		justify-content: center;
	}

	.gpu__content.author-ofthemonth h2 {
		margin-bottom: unset;
	}

	.gpu__content.author-ofthemonth img {
		margin: 30px 0px;
	}

	.gpu__content.author-ofthemonth .author__wrapper {
		margin-left: 0px;
	}

	.gpu__content.update-book img {
		width: 100%;
	}

	.update-book.title-book {
		width: 100%;
	}

	header .nav-item .nav-link.active {
		border-bottom: unset;
	}

	header .navbar-nav {
		display: block;
		align-items: unset;
	}

	.form__search {
		margin-right: 0px;
		width: auto;
	}

	.logo__gpu-desktop {
		display: none;
	}

	.logo__gpu-mobile {
		display: block;
	}

	header .navbar-brand {
		margin-right: 0px;
		margin-left: 0px;
	}

	.btn.mobile-login {
		display: block;
		background: #009688;
		margin-bottom: 0px;
	}

	.form-control.custom--input {
		margin-bottom: 0px;
		width: 200px;
	}

	.navbar-light .navbar-toggler {
		margin-bottom: 0px;
	}

	.search-icon {
		top: 27%;
	}

	header .btn.btn-primary--custom {
		display: none;
	}

	.btn.btn-secondary--custom {
		display: none;
	}

	.carousel-item img {
		height: 346px;
	}

	header .navbar {
		padding-bottom: unset;
	}

	.gpu__content.new-release {
		position: relative;
		/* bottom: 100px; */
	}

	.detail-buku .detail-buku-wrap {
		flex-wrap: wrap;
	}

	.gpu__content.detail-buku {
		margin: 20px;
	}

	.gpu__content.related-books .related-books__category {
		margin-bottom: 5px;
	}

	.gpu__content.related-books .related-books__title {
		margin-bottom: 5px;
	}

	.gpu__content.updates-promo .custom--flex-wrap {
		flex-wrap: wrap;
	}

	.gpu__content.related-books .custom--flex-wrap {
		flex-wrap: wrap;
	}

	aside.sharebar {
		display: none;
	}

	.update-promo__img img {
		max-width: 100%;
		height: 100%;
	}

	.updates-promo__detail {
		margin-top: 20px;
		margin-left: 0px;
	}

	.promo-desc__label {
		width: 100%;
	}

	.wrapper__tabs-content input {
		width: 100% !important;
	}

	.gpu__content.my-profile {
		flex-wrap: wrap;
	}

	.wrapper__my-profile-tabs {
		display: block;
		padding: 20px;
	}

	.wrapper__tabs-content .btn.btn-login--custom {
		width: 100%;
	}

	.wrapper__my-profile-sidebar {
		width: 100%;
		margin-right: unset;
	}

	.wrapper__about-author {
		flex-wrap: wrap;
	}

	.wrapper__about-author .about-author__detail {
		margin-top: 20px;
		margin-left: 0;
	}

	.wrapper__author-books-list {
		flex-wrap: wrap;
	}

	.author-books__detail {
		padding-left: 0;
		padding: 20px 0px;
	}

	.wrapper__author-books h2 {
		margin-bottom: unset;
	}

	.icon-upload {
		left: 46%;
	}

	.detail-buku__container {
		width: 100%;
	}

	footer {
		border-top: unset;
		margin-top: unset;
	}

	.footer__list {
		margin-top: 30px;
	}

	.wrapper__secondary-footer {
		flex-direction: column-reverse;
	}

	.flex-link-footer {
		justify-content: center;
	}

	.secondary-footer__desc {
		padding-left: 20px;
		padding-top: 20px;
	}

	.wrapper__sidebar-category {
		width: 100%;
	}

	.gpu__content.list-buku {
		flex-wrap: wrap;
	}

	.wrapper__list-buku .form-select {
		margin-bottom: 10px;
		width: 174px;
	}

	.content-filter {
		display: block;
	}

	.list-buku__datetime {
		margin-bottom: 10px !important;
		width: 174px !important;
	}

	.wrapper__list-buku-datetime {
		margin-left: unset;
	}

	.btn.btn-download {
		margin-left: unset;
		width: auto;
	}

	.gpu__content.list-buku .bestselling__wrapper {
		justify-content: unset;
	}

	.wrapper__list-buku .row .col-lg-3.col-md-6.col-sm-12 {
		margin-bottom: 10px;
	}

	.book__wrapper-custom {
		width: 100%;
		margin-bottom: 0px;
	}

	.wrapper__list-buku .row {
		margin-left: 0;
	}

	.wrapper__list-categories-pagination {
		margin-left: 0;
	}

	.list-authors__search {
		width: 100% !important;
	}

	.wrapper__form-select {
		width: 100%;
	}

	.wrapper__list-authors .form-select {
		width: 100%;
		margin-bottom: 10px;
	}

	.wrapper__list-authors .wrapper__form-select {
		margin-right: 10px;
	}

	.wrapper__search-author {
		position: relative;
		width: 100%;
		margin-right: 10px;
	}

	.wrapper__list-authors-label {
		flex-wrap: wrap;
	}

	.wrapper__detail-authors {
		justify-content: center;
	}

	.content__detail-author {
		margin-right: 20px;
		margin-left: 40px;
	}

	.gpu__content.search-result .wrapper__detail-authors {
		flex-wrap: wrap;
	}

	.gpu__content.search-result .wrapper__form-search {
		flex-wrap: wrap;
		margin-bottom: 20px;
	}

	.gpu__content.search-result .form-select {
		width: 100%;
	}

	.gpu__content.search-result .wrapper__form-select {
		margin-right: 0;
	}

	.wrapper__list-categories-pagination.custom--margin {
		margin: 0;
	}

	.wrapper__list-buku .row.custom--maxwidth .update-book.preview {
		width: 100%;
	}

	.wrapper__list-buku .row.custom--maxwidth .update-book.title-book {
		margin-top: 15px;
	}

	.wrapper__list-buku.custom--margin {
		margin-left: 20px;
		margin-top: 20px;
	}

	.wrapper__reviews-list {
		flex-wrap: wrap;
	}

	.reviews-list__name {
		margin-top: 10px;
	}

	.wrapper__contactus {
		flex-wrap: wrap;
		margin-left: 20px;
	}

	.contactus__maps iframe {
		width: 100%;
	}

	.wrapper__faq {
		margin-left: 10px;
	}

	.international-partnership__title {
		width: 100%;
	}

	.international-partnership__img {
		width: 100%;
		padding: 0px 30px;
	}

	.wrapper__form-joinus input {
		width: 100% !important;
	}

	.gpu__content.related-books h3 {
		width: 100%;
	}

	.wrapper__carousel-quote img.book-quotes__img {
		margin-bottom: 30px;
	}

	.gpu__content.update-book img {
		width: 100%;
		height: auto;
	}

}

@media screen and (min-device-width: 1000px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
	header .navbar-nav {
		display: flex;
		align-items: center;
	}

	.form__search {
		margin-right: 0px;
	}

	header .navbar-brand {
		margin-right: 10px;
		margin-left: 10px;
	}
}


@media screen and (min-device-width: 769px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
	.form-control.custom--input {
		width: 100%;
	}

	.form__search {
		width: 60%;
	}

	.bg--custom {
		background: #ffffff;
		height: auto;
	}

	header .btn.btn-primary--custom {
		display: none;
	}

	header .btn.btn-secondary--custom {
		display: none;
	}

	.logo__gpu-mobile {
		display: block;
	}

	.logo__gpu-desktop {
		display: none;
	}

	.btn.mobile-login {
		display: block;
		background: #009688;
		margin-bottom: 0px;
	}
}


@media (min-width: 1200px) {
	.navbar-expand-custom {
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
	}

	.navbar-expand-custom .navbar-nav {
		flex-direction: row;
	}

	.navbar-expand-custom .dropdown-menu {
		position: absolute;
	}

	.navbar-expand-custom .nav-link {
		padding-right: .5rem;
		padding-left: .5rem;
	}

	.navbar-expand-custom>.container {
		flex-wrap: nowrap;
	}

	.navbar-expand-custom .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}

	.navbar-expand-custom .navbar-toggler {
		display: none;
	}
}

/* Media Query for high resolution Tablets or Ipads */
@media (min-width: 768px) and (max-width: 1024px) {
	.update-book.preview {
		margin-bottom: 40px;
	}

	.bestselling__wrapper {
		flex-wrap: wrap;
		justify-content: center;
		margin-left: 60px !important;
	}

	.book__wrapper {
		position: relative;
	}

	.gpu__content.new-release .carousel__title {
		width: 170px;
	}

	.col-custom-5 {
		margin-bottom: 20px;
		margin-right: unset;
	}

	.gpu__content.bestselling img {
		width: 80%;
	}

	.gpu__content.bestselling {
		margin-bottom: 0px;
	}

	.author-ofthemonth--flex-wrap {
		margin-top: 20px;
	}

	.footer__desc.email {
		word-break: break-all;
	}

	.btn.btn__footer-email {
		font-size: 14px;
	}

	.detail-buku .detail-buku-wrap {
		flex-wrap: wrap;
	}

	.gpu__content.related-books .custom--flex-wrap {
		flex-wrap: wrap;
	}

	.update-promo__img img {
		max-width: 100%;
		height: 100%;
	}

	.gpu__content.updates-promo .custom--flex-wrap {
		flex-wrap: wrap;
		justify-content: center;
	}

	.updates-promo__detail {
		margin-top: 30px;
	}

	.bg--custom {
		background: #ffffff;
		height: auto;
	}

	.form-control.custom--input {
		width: 100%;
	}

	.form__search {
		width: 60%;
	}

	.logo__gpu-mobile {
		display: block;
	}

	.logo__gpu-desktop {
		display: none;
	}

	.btn.mobile-login {
		display: block;
		background: #009688;
		margin-bottom: 0px;
	}

	.carousel {
		margin-top: 90px;
	}

	.btn.btn-download {
		font-size: 14px;
	}

	.list-group.list-buku .list-group-item a {
		word-break: break-all;
	}

	.wrapper__list-buku h2 {
		margin-bottom: 10px;
	}

	.list-custom--padding li {
		margin-left: 10px;
	}

	.gpu__content.list-buku .bestselling__wrapper {
		margin: 40px 0px;
	}

	.wrapper__list-buku .row {
		margin-left: 0;
	}

	.wrapper__list-categories-pagination {
		margin-left: 0;
	}

	.content__detail-author {
		margin-right: 20px;
		margin-left: 40px;
	}

	.gpu__content.search-result .wrapper__detail-authors {
		flex-wrap: wrap;
	}

	.wrapper__list-categories-pagination.custom--margin {
		margin: 20px;
	}

	.wrapper__list-buku .row.custom--maxwidth .update-book.title-book {
		margin-top: 15px;
	}

	.wrapper__list-buku .row.custom--maxwidth .update-book.preview {
		width: 100%;
	}

	.wrapper__reviews-list {
		flex-wrap: wrap;
	}

	.reviews-list__name {
		margin-top: 10px;
	}

	.contactus__maps iframe {
		width: 350px;
	}

	.international-partnership__title {
		width: 100%;
	}

	.bestselling__wrapper .col-custom-5:last-child {
		display: none;
	}

	.content-filter-2 {
		width: 100%;
	}

}


@media only screen and (max-width: 365px) {
	.form-control.custom--input {
		width: 170px;
	}
}

@media only screen and (max-width: 480px) {
	.input-password-wrapper{
		width: 100%;
	}
}

@media (min-width: 992px) {
	.gpu__content.list-buku .row.list-buku-row .col-lg-3 {
		width: 20%;
	}

	.gpu__content.list-authors .row .col-lg-3.custom-5row {
		width: 20%;
	}
}

.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 1000;
	top: 0;
	left: 0px;
	opacity: 0.5;
	filter: alpha(opacity=50);
	background-color: #e4e4e4;
}